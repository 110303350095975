<div class="wallet__cards-container">
  <mat-card class="wallet__card">
    <mat-card-header fxLayoutAlign="center center">
      <mat-card-title fxLayoutAlign="center center">
        {{silver}}
        <app-coin type="silver"></app-coin>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <p>Silver coins -
        <app-coin type="silver"></app-coin>
        can be used only for purchasing our swag.
      </p>
      <p>You can't transfer them to another users.</p>
      <p>You can't exchange them for gold coins. </p>
      <p>You will get them on particular dates:</p>
      <ul class="main-list">
          <li>
              Birthday (for celebrant only) -
              <span class="coin-value">500</span>
              <app-coin type="silver" size="small"></app-coin>
          </li>
          <li>
              Unibrix's Birthday (31st of March) -
              <span class="coin-value">200</span>
              <app-coin type="silver" size="small"></app-coin>
          </li>
          <li>
              Knowladge sharing (presentation, report preparation) -
              <span class="coin-value">1000</span>
              <app-coin type="silver" size="small"></app-coin>
          </li>
          <li>
              Professional Days -
              <span class="coin-value">200</span>
              <app-coin type="silver" size="small"></app-coin>
              <ul class="sub-list">
                  <li>Developer's Day (7th of January)</li>
                  <li>Designer's Day (27th of April)</li>
                  <li>HR's Day (20th of May)</li>
                  <li>Accountant and Sales Day (16th of July)</li>
                  <li>Tester's Day (9th of September)</li>
              </ul>
          </li>
          <li>
              Mentoring a new employee -
              <span class="coin-value">1000</span>
              <app-coin type="silver" size="small"></app-coin>
          </li>
          <li>
              Attending a professional conference (Meetup) -
              <span class="coin-value">500</span>
              <app-coin type="silver" size="small"></app-coin>
          </li>
          <li>
              Taking position courses -
              <span class="coin-value">500</span>
              <app-coin type="silver" size="small"></app-coin>
          </li>
          <li>
              Bringing a potential candidate -
              <span class="coin-value">300</span>
              <app-coin type="silver" size="small"></app-coin>
          </li>
          <li>
              Assisting in organising events -
              <span class="coin-value">100</span>
              <app-coin type="silver" size="small"></app-coin>
          </li>
      </ul>
    </mat-card-content>
  </mat-card>

  <mat-card class="wallet__card">
    <mat-card-header fxLayoutAlign="center center">
      <mat-card-title fxLayoutAlign="center center">
        {{gold}}
        <app-coin type="gold"></app-coin>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <p>Gold coins -
        <app-coin type="gold"></app-coin>
        can be used only for transferring to another user.
      </p>
      <p>
        Gold coins, which you transferred to a friend, become
        <app-coin type="silver"></app-coin>
        silver coins for him.
      </p>
      <p>You can't buy things by those.</p>
      <p> You will get them only by personal activity: </p>
      <ul class="main-list">
          <li>
              Employment anniversery -
              <span class="coin-value">500</span>
              <app-coin type="gold" size="small"></app-coin>
          </li>
          <li>
              National Holidays -
              <span class="coin-value">100</span>
              <app-coin type="gold" size="small"></app-coin>
              <ul class="sub-list">
                  <li>New Year (1st of January)</li>
                  <li>St. Valentine`s Day (14th of February)</li>
                  <li>8th of March (gold for girls only)</li>
                  <li>Easter</li>
                  <li>Pentecost</li>
                  <li>Constitution Day of Ukraine (28th of June)</li>
                  <li>Statehood Day (15th of July)</li>
                  <li>Independence Day (24 August)</li>
                  <li>Day of Defenders of Ukraine (1st of October)</li>
                  <li>St. Nicola`s Day (6th of December)</li>
                  <li>Christmas (25th of December)</li>
              </ul>
          </li>
          <li>
              Bringing a potential candidate -
              <span class="coin-value">300</span>
              <app-coin type="gold" size="small"></app-coin>
          </li>
          <li>
              Assisting in organising events -
              <span class="coin-value">100</span>
              <app-coin type="gold" size="small"></app-coin>
          </li>
      </ul>

      <div>
        <div fxFlex="1 1 auto"></div>
        <button (click)="openTransferPopup()"
                color="accent"
                mat-button
                mat-raised-button>
          <span> Send
            <app-coin type="gold"></app-coin>
            to a friend
          </span>
        </button>
      </div>
    </mat-card-content>
  </mat-card>
</div>

<mat-tab-group>
  <mat-tab label="Transactions">
    <app-user-transactions [isUserSelectorDisplayed]="false"></app-user-transactions>
  </mat-tab>

  <mat-tab label="Orders">
    <app-user-orders (cancelOrderEvent)="this.fetchCoinsCount()"></app-user-orders>
  </mat-tab>
</mat-tab-group>
